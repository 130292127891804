import React from 'react';
import PricingCard from './pricing-card';

import { useTranslation } from 'react-i18next';
import { Section, Container, Title, TextContainer, SubTitle } from 'src/theme/styledComponents';
import styled from 'styled-components';

const Wrapper = styled(Container)`
	color: ${props => props.theme.colors.secondary};
`;

const Packages = styled.div`
	display: grid;
	grid-gap: 50px;
	margin-top: 50px;
	justify-content: center;
	grid-template-columns: 95%;
	@media (max-width: 1024px) {
		grid-template-columns: 95%;
		grid-gap: 75px;
	}
`;

const Pricing = () => {
	const { t: translate } = useTranslation();

	return (
		<Section id="pricing">
			<Wrapper>
				<Title>{translate('pricing.title')}</Title>
				<SubTitle>{translate('pricing.online')}</SubTitle>
				<SubTitle>{translate('pricing.discounts')}</SubTitle>
				<TextContainer>
					<Packages>
						{translate('pricing.packages', { returnObjects: true }).map((pack, index) => (
							<PricingCard key={index} index={index} {...pack} />
						))}
					</Packages>
				</TextContainer>
			</Wrapper>
		</Section>
	);
};

export default Pricing;
