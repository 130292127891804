import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Section, Container, Text, TextContainer } from 'src/theme/styledComponents';
import styled from 'styled-components';

const Wrapper = styled(Container)`
	color: ${props => props.theme.colors.light};
`;

const Texts = styled(TextContainer)`
	grid-gap: 15px;
`;

const SubTitle = styled(Text)`
	width: 70%;
	line-height: 25px;
	text-align: left;
`;

const ListItem = styled(Text)`
	display: flex;
	& ${Text} {
		margin-left: 10px;
	}
`;

const Recommend = () => {
	const { t: translate } = useTranslation();

	return (
		<Section id="recommend" background="primary">
			<Wrapper>
				<Title center>{translate('recommend.title')}</Title>
				<Texts>
					<SubTitle bold>{translate('recommend.subtitle')}</SubTitle>
					{translate('recommend.texts', { returnObjects: true }).map((text, index) => (
						<ListItem key={index}>
							{index + 1}, <Text>{text}</Text>
						</ListItem>
					))}
				</Texts>
			</Wrapper>
		</Section>
	);
};

export default Recommend;
