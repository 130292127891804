export default {
	translation: {
		contact: {
			title: 'Contact',
			subtitle: 'Please fill out the form to contact me.',
			name: 'Name',
			email: 'Email',
			text: 'Text',
			send: 'Send',
			accept: 'I agree to the ',
			rulesOfDataProcessing: 'Rules of Personal Data Processing.',
		},
		nav: {
			businessCoaching: { text: 'Business Coaching', link: '#business-coaching' },
			languageCoaching: { text: 'Language Coaching', link: '#language-coaching' },
			aboutMe: { text: 'About Me', link: '#about-me' },
			aboutCoaching: { text: 'About Coaching', link: '#about-coaching' },
			isItForMe: { text: 'Is it for me?', link: '#recommend' },
			services: { text: 'Services', link: '#services' },
			contact: { text: 'Contact', link: '#contact-form' },
		},
		heroTitle: 'Discover your own inner resources!',
		apply: 'Apply',
		more: 'More...',
		less: 'Less',
		aboutMe: {
			title: 'About Me',
			p1:
				'My name is Bori Nagy. I am a life and business coach, ESOL teacher, international student support advisor. I did a postgraduate specialist training course in business coaching at ELTE, Budapest. I learnt from professional, qualified coaches and also from great lecturers of the Institute of Psychology at ELTE, Faculty of Education and Psychology.',
			p2:
				'I have been dealing with people in education fields for over 25 years, with various age groups in different types of institutions, such as in language schools, adult education centres, companies or at university.What I like about coaching the most is that it brings everything together that I really enjoy in my supportive profession: the observing role, the supportive attitude, and most of all, the joy  we feel, when someone manages to solve a problem, a life or work situation or get over their difficulties.',
		},
		aboutCoaching: {
			title: 'About Coaching',
			coachingDetails: [
				{
					title: 'What is going to happen in coaching?',
					texts: [
						'We sit down to talk about a topic, a question or a dilemma that you find important.',
						'I ask questions, I use certain methods, materials that support you, might show you new viewpoints, help you with discovering your own answers, solutions.',
						'I will not give you any advice or  tell you what to do. Only your own solution is really yours. However, I will support you step by step, accompany you as long as you reach a cornerstone from where you can continue your journey without me.',
						'In coaching I help you with self-development, discovering, reinforcing and activating your own inner resources.',
					],
				},
				{
					title: 'What is not going to happen in coaching?',
					important: 'Important: ',
					texts: [
						'Coaching is not a psychotherapy. I will not analyse, discover your past. We proceed with a certain topic, question or problem, previously agreed on a clear goal, focusing on the solution. The question is what you would like to achieve and what you do for it.',
					],
				},
			],
		},
		recommend: {
			title: 'Is coaching for me?',
			subtitle: 'Yes, if 3 of the following 7 statements are true about you:',
			texts: [
				'I am stuck.',
				'I know that I should do something but it is unclear, when, how and which direction.',
				'I would like to reach my goals faster.',
				'I would like to be more successful.',
				'I am open to change.',
				'I accept support.',
				'I want to take control of my life at last.',
			],
		},
		services: {
			title: 'Services',
			subTitle: 'Discover your own inner resources!',
			toOffer: 'What can I offer you as a coach?',
			cards: {
				support: { text: 'accepting, supportive, empathic atmosphere', color: '#BFA3D0' },
				solution: { text: 'focus on solution', color: '#BFA3D0' },
				interest: { text: 'discrete but honest curiosity, interest and attention', color: '#190264' },
				strength: { text: 'discovering and making you aware of your own values and strengths', color: '#190264' },
				question: { text: 'various, forward-looking questions', color: '#FFBF00' },
				plan: { text: 'action plan for you', color: '#FFBF00' },
				sketch: { text: 'short  summary of every session via email', color: '#F6AD88' },
			},
		},
		businessCoaching: {
			btn: 'Contact me',
			title: 'Business Coaching',
			subtitle:
				' I can support  leaders, team leaders, team members to develop their leadership and other skills and competencies in order to create an even more smooth and effective communication and work flow within the organisation. ',
			subtitle2: ' If the following questions, statements make you think, coaching might be useful for you or for your team:',
			texts: [
				'What values are the most important to me as a leader?',
				'Do my  employees/colleagues know the values I find prior? ',
				'Does my team have values in common?',
				'I regularly do overtime and often fail to delegate.',
				'I often feel stressed and tense, it affects my way of communication.',
			],
		},
		connect: {
			title: 'Why „Connect Coaching”?',
			subtitle: 'Connection to one another has a special role in coaching.',
			subtitle2: 'Connection enables us to:',
			texts: [
				"influence each others' intuitive processes and therefore inspire each other;",
				'find the right way to explore new perspectives;',
				'trust each other and accept new ideas and thoughts;',
				'support one another while allowing them to walk on their own path.',
			],
			endtitle:
				'What I find amazing in connection is that it is always mutual, depends on both of us and can come up with something new you have never thought of before.',
		},
		footer: {
			coTitle: 'Company details',
			hq: 'HQ address: 2120 Dunakeszi Kolonics György utca 2.',
			taxNr: 'Tax number: 55414317-1-33',
			regNr: 'Registration number: 54089761',
			contactTitle: 'Contact',
			email: 'nagybori@connectcoaching.hu',
			phone: '+36 30 997 7201',
		},
		pricing: {
			title: 'Prices',
			online: 'Meetings can be held online.',
			discounts: '15% off for students and teachers',
			packages: [
				{
					title: 'Introductory conversation',
					text: 'On messenger or skype',
					price: 'Free - 20min',
					btnTxt: 'Select',
				},
				{
					title: 'Introductory Session',
					lastPrice: 'HUF 12 000/60 mins',
					btnTxt: 'Select',
				},
				{
					title: 'Connect Start',
					text: '3 Sessions',
					lastPrice: 'HUF 45 000',
					additional: 'HUF 15 000/60 mins',
					btnTxt: 'Select',
				},
				{
					title: 'Connect Complex',
					text: '5 Sessions',
					lastPrice: 'HUF 65 000',
					additional: 'HUF 13 000/60 mins',
					btnTxt: 'Select',
				},
				{
					title: 'Connect Change',
					text: '8 Sessions',
					lastPrice: 'HUF 96 000',
					additional: 'HUF 12 000/60 mins',
					btnTxt: 'Select',
				},
				{
					title: 'Connect Individual',
					text: 'If you would like something different, please, contact me.',
					btnTxt: 'Contact',
				},
			],
		},
	},
};
