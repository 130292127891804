import React from 'react';
import CoachingDetail from './coaching-detail';

import { useTranslation } from 'react-i18next';
import { Section, Container, Title } from 'src/theme/styledComponents';
import styled from 'styled-components';

const Wrapper = styled(Container)`
	margin: 0 auto;
	color: ${props => props.theme.colors.secondary};
`;

const Details = styled.div`
	width: auto;
	text-align: center;
`;

const AboutCoaching = () => {
	const { t: translate } = useTranslation();

	return (
		<Section id="about-coaching">
			<Wrapper>
				<Title color="secondary">{translate('aboutCoaching.title')}</Title>
				<Details>
					{translate('aboutCoaching.coachingDetails', { returnObjects: true }).map((detail, index) => (
						<CoachingDetail key={index} {...detail} />
					))}
				</Details>
			</Wrapper>
		</Section>
	);
};

export default AboutCoaching;
