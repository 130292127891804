import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Section, Container, Text, TextContainer, Button, Link } from 'src/theme/styledComponents';
import styled from 'styled-components';

const Wrapper = styled(Container)`
	color: ${props => props.theme.colors.secondary};
`;

const Texts = styled(TextContainer)`
	grid-gap: 15px;
	margin-bottom: 30px;
`;

const SubTitle = styled(Text)`
	margin: 25px 0;
	line-height: 25px;
	text-align: justify;
`;

const ListItem = styled(Text)`
	display: flex;
	& ${Text} {
		margin-left: 10px;
	}
`;

const BusinessCoaching = () => {
	const { t: translate } = useTranslation();
	return (
		<Section id="business-coaching">
			<Wrapper>
				<Title color="secondary" center>
					{translate('businessCoaching.title')}
				</Title>
				<Texts>
					<SubTitle>{translate('businessCoaching.subtitle')}</SubTitle>
					<SubTitle>{translate('businessCoaching.subtitle2')}</SubTitle>
					{translate('businessCoaching.texts', { returnObjects: true }).map((text, index) => (
						<ListItem key={index}>
							{index + 1}, <Text>{text}</Text>
						</ListItem>
					))}
				</Texts>
				<Link background="secondary" padding="20px 40px" href="#contact-form">
					{translate('businessCoaching.btn')}
				</Link>
			</Wrapper>
		</Section>
	);
};

export default BusinessCoaching;
