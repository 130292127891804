import React from 'react';
import { useTranslation } from 'react-i18next';
import heroImg from 'src/images/hero-2-min.jpg';
import styled from 'styled-components';
import { Section, Title, Link, Container, Button } from 'src/theme/styledComponents';

const Wrapper = styled(Container)`
	min-height: 80vh;
	flex-direction: row;
	justify-content: initial;
	color: ${props => props.theme.colors.primary};
`;

const TitleContainer = styled.div`
	width: 40%;
	z-index: 2;
	& ${Title}, ${Link} {
		margin: 15px 0;
	}
	@media (max-width: 1024px) {
		width: 80%;
		padding: 20px;
		transform: translateY(-130px);
	}
	@media (max-width: 500px) {
		width: 100%;
		transform: translateY(-50px);
	}
`;

const ApplyButton = styled(Link)`
	font-size: 32px;
	padding: 18px 30px;
	width: 60%;
	@media (max-width: 768px) {
		font-size: 22px;
		padding: 10px 50px;
	}
	@media (max-width: 500px) {
		font-size: 16px;
		padding: 10px 40px;
	}
`;

const Img = styled.img`
	width: 65%;
	position: absolute;
	bottom: 30px;
	right: -100px;
	border-radius: 10px;
	margin-right: 10px;
	@media (max-width: 1024px) {
		bottom: 30px;
		right: 0px;
		border-radius: 10px;
		width: 80%;
		margin-right: 10px;
	}
`;

const Hero = () => {
	const { t: translate } = useTranslation();

	return (
		<Section>
			<Wrapper>
				<TitleContainer>
					<Title color="primary">{translate('heroTitle')}</Title>
					<ApplyButton href="#contact-form">{translate('apply')}</ApplyButton>
				</TitleContainer>
				<Img src={heroImg} alt="Hero image" />
			</Wrapper>
		</Section>
	);
};

export default Hero;
