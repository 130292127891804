import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Section, Button, Title, SubTitle, Text } from 'src/theme/styledComponents';
import RulesOfDataProcessingEn from 'src/images/THE_RULES_OF_DATA_PROCESSING_EN.pdf';
import RulesOfDataProcessingHu from 'src/images/THE_RULES_OF_DATA_PROCESSING_HU.pdf';

const InputField = styled.input`
	border: none;
	width: 100%;
	height: ${props => props.heightX * 40 || 40}px;
	margin: 15px 15px 15px 0;
	padding-left: 10px;
	border-radius: 6px;
	font-size: 14px;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
	@media screen and (max-width: 920px) {
		margin-left: 0px;
	}
	&::placeholder {
		color: ${props => props.theme.colors.secondary};
	}
	&:focus {
		border-bottom: 2px solid ${props => props.theme.colors.secondary};
	}
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	outline: none;
`;

const InputArea = styled.textarea`
	border: none;
	width: 100%;
	height: ${props => props.heightX * 40 || 40}px;
	margin: 15px 15px 15px 0;
	padding: 10px;
	border-radius: 6px;
	font-size: 14px;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
	@media screen and (max-width: 920px) {
		margin-left: 0px;
	}
	&::placeholder {
		color: ${props => props.theme.colors.secondary};
	}
	&:focus {
		border-bottom: 2px solid ${props => props.theme.colors.secondary};
	}
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	outline: none;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 60;
	color: ${props => props.theme.colors.secondary};
	@media screen and (max-width: 920px) {
		flex-direction: column;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;
`;

const ContactForm = () => {
	const { t: translate, i18n } = useTranslation();
	const [accepted, setAccepted] = useState(false);
	return (
		<Section id="contact-form" style={{ padding: '80px' }} background="lighterPurpleShadow">
			<Title color="secondary">{translate('contact.title')}</Title>
			<Wrapper>
				<Column>
					<SubTitle textAlign="left" lineHeight="50px" color="secondary">
						{translate('contact.subtitle')}
					</SubTitle>
				</Column>
				<Column>
					<form name="contact" method="POST" data-netlify="true">
						<input type="hidden" name="form-name" value="contact" />
						<InputField placeholder={translate('contact.name')} name="name"></InputField>
						<InputField placeholder={translate('contact.email')} name="email"></InputField>
						<InputArea
							cols="40"
							rows="5"
							style={{ marginBottom: '40px' }}
							heightX={3}
							placeholder={translate('contact.text')}
							name="text"
						></InputArea>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
							<input style={{ marginRight: '10px' }} type="checkbox" onChange={() => setAccepted(!accepted)} />
							<Text textAlign="left">
								{translate('contact.accept')}
								<a
									href={i18n.language === 'en' ? RulesOfDataProcessingEn : RulesOfDataProcessingHu}
									rel="noopener noreferrer"
									target="_blank"
								>
									{translate('contact.rulesOfDataProcessing')}
								</a>
							</Text>
						</div>
						<Button background={accepted ? 'secondary' : 'greyShadow'} disabled={!accepted} type="submit">
							{translate('contact.send')}
						</Button>
					</form>
				</Column>
			</Wrapper>
		</Section>
	);
};

export default ContactForm;
