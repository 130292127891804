import React from 'react';

import Layout from 'src/components/layout';
import SEO from 'src/components/seo';
import { useTranslation } from 'react-i18next';
import {
	AboutMe,
	AboutCoaching,
	Pricing,
	Hero,
	Recommend,
	Services,
	BusinessCoaching,
	LanguageCoaching,
	AboutConnect,
	Footer,
	ContactForm,
} from 'src/components';

import '../i18n';

const IndexPage = () => {
	const { i18n } = useTranslation();
	return (
		<>
			<Layout>
				<SEO title="Connect Coaching" />
				<Hero />
				<AboutMe />
				<AboutConnect />
				<AboutCoaching />
				<Services />
				<Recommend />
				<BusinessCoaching />
				{i18n.language === 'hu' && <LanguageCoaching />}
				<Pricing />
				<ContactForm />
				<Footer />
			</Layout>
		</>
	);
};

export default IndexPage;
