export default {
	translation: {
		contact: {
			title: 'Kapcsolat',
			subtitle: 'Kérlek az űrlap segítségével vedd fel velem a kapcsolatot.',
			name: 'Név',
			email: 'Email',
			text: 'Szöveg',
			send: 'Küldés',
			accept: 'Elfogadom az ',
			rulesOfDataProcessing: 'Adatkezelési Nyilatkozatot.',
		},
		nav: {
			businessCoaching: { text: 'Business Coaching', link: '#business-coaching' },
			languageCoaching: { text: 'Language Coaching', link: '#language-coaching' },
			aboutMe: { text: 'Rólam', link: '#about-me' },
			aboutCoaching: { text: 'Mi a Coaching?', link: '#about-coaching' },
			isItForMe: { text: 'Kinek ajánlom?', link: '#recommend' },
			services: { text: 'Szolgáltatásaim', link: '#services' },
			contact: { text: 'Kapcsolat', link: '#contact-form' },
		},
		heroTitle: 'Fedezd fel rejtett erőforrásaid!',
		apply: 'Jelentkezz',
		more: 'Több...',
		less: 'Kevesebb',
		aboutMe: {
			title: 'Rólam',
			p1:
				'Nagy Bori vagyok. Coach, angol nyelvtanár, nemzetközi hallgatói referens. A coach üzleti edző akkreditált szakirányú továbbképzést az ELTE-n végeztem, ahol a legjobbaktól, tapasztalt, elismert coachoktól, valamint az ELTE PPK Pszichológiai Intézetének kiváló oktatóitól tanultam.',
			p2:
				'25 éve foglalkozom emberekkel, a legkülönbözőbb korosztályokkal, különböző intézményekben; nyelvtanárként nyelviskolákban, felnőttképzési intézményekben, multinacionális cégnél vagy nemzetközi hallgatói referensként egyetemen. A coachingban összeadódik számomra mindaz, amit a támogató hivatásomban szeretek – megfigyelő szerep, támogató attitűd, és legfőképpen a közös öröm, hogy sikerült megoldani egy élethelyzetet, elakadást, egy munkahelyi problémát, sikerült fejlődni, új nézőpontokat találni, vezetői és egyéb kompetenciákban erősödni.',
		},
		connect: {
			title: 'Miért „Connect Coaching”?',
			subtitle:
				'A „connect” szó az angolban azt jelenti: kapcsolódni. A coachingban az egymáshoz való kapcsolódásnak különleges szerepe van.',
			subtitle2: 'A kapcsolódás képessé tesz arra, hogy:',
			texts: [
				'hatással legyünk egymás intuíciós folyamataira és ezáltal inspiráljuk egymást;',
				'megtaláljuk a megfelelő módot, utat újabb nézőpontok megismerése felé;',
				'bízzunk egymásban és elfogadjunk új ötleteket, gondolatokat;',
				'úgy támogassuk egymást, hogy közben engedjük a másikat a maga útját járni.',
			],
			endtitle:
				'A kapcsolódásban az a fantasztikus, hogy kölcsönös, mindkettőnkön múlik, és mindig tartogat valami újat, amire nem gondoltunk azelőtt.',
		},
		aboutCoaching: {
			title: 'Mi a Coaching?',
			coachingDetails: [
				{
					title: 'Mi történik egy coaching folyamatban?',
					texts: [
						'Leülünk beszélgetni egy neked fontos témáról, kérdésről, dilemmáról.',
						'Olyan kérdéseket teszek fel, esetenként eszközöket, technikákat alkalmazok, melyek támogatnak, új nézőpontokat adhatnak, és segítenek, hogy saját magad hívd elő a válaszokat, megoldásokat.',
						'Nem adok tanácsot, nem mondom meg, mit kell tenned, csak a saját megoldásod a tiéd. Ugyanakkor tudatosan támogatlak, kísérlek addig a pontig, ahonnan már egyedül is tovább tudsz menni.',
						'A coachingban az önfejlesztésben segítelek, hogy felfedezd, megerősítsd és aktiváld az erősségeidet, belső erőforrásaidat.',
					],
				},
				{
					title: 'Mi nem történik egy coaching folyamatban?',
					important: 'Fontos: ',
					texts: [
						'A coaching nem pszichoterápia, nem elemezzük, nem boncolgatjuk a múltad. Egy adott témában, kérdésben célirányosan, megoldásközpontúan haladunk. A kérdés: te mit szeretnél az adott helyzetben és mit teszel érte?',
					],
				},
			],
		},
		recommend: {
			title: 'Kinek ajánlom?',
			subtitle: 'Neked, ha az 7 állításból legalább 3 igaz rád:',

			texts: [
				'Elakadtam.',
				'Érzem, hogy lépnem kellene, de nem világos, mikor, hogyan, merre.',
				'Szeretném gyorsabban elérni a céljaimat.',
				'Szeretnék sikeresebb lenni.',
				'Nyitott vagyok a változtatásra, a változásra.',
				'Elfogadom a támogatást.',
				'Szeretném végre a kezembe venni az életem alakulását.',
			],
		},
		services: {
			title: 'Szolgáltatásaim',
			subTitle: 'Fedezd fel a rejtett erőforrásaid!',
			toOffer: 'Mit tudok coachként nyújtani neked?',
			cards: {
				support: { text: 'elfogadó, támogató, empatikus légkört', color: '#BFA3D0' },
				solution: { text: 'megoldás-központúságot', color: '#BFA3D0' },
				interest: { text: 'diszkrét, de őszinte kíváncsiságot, érdeklődést, figyelmet', color: '#190264' },
				strength: { text: 'értékeid, erősségeid feltárását', color: '#190264' },
				question: { text: 'változatos, előremutató kérdéseket', color: '#FFBF00' },
				plan: { text: 'rád szabott akciótervet', color: '#FFBF00' },
				sketch: { text: 'rövid, összefoglaló vázlatot minden ülés után emailben', color: '#F6AD88' },
			},
		},
		businessCoaching: {
			btn: 'Kérj ajánlatot',
			title: 'Business Coaching',
			subtitle:
				'A szervezet vezetőit, középvezetőit, a csapattagokat tudom támogatni abban, hogy vezetői és egyéb készségeik, képességeik fejlesztése, megerősítése mentén gördülékenyebbé váljon a cégen belüli kommunikáció és hatékonyabbá váljanak a munkafolyamatok.',
			subtitle2: 'Ha az alábbi kérdések, állítások elgondolkodtatnak, hasznos lehet számodra a vezetői coaching:',
			texts: [
				'Melyek a legfontosabb értékeim vezetőként?',
				'Esetenként az a benyomásom, hogy a munkatársaim nem értik a működési elveket, folyamatokat.',
				'Van-e a csapatomnak közös értékrendszere?',
				'Rendszeresen túlórázom és próbálom behozni a csapat lemaradását.',
				'Sokszor érzem magam stresszesnek, feszültnek, ez hat a kommunikációmra is.',
			],
		},
		languageCoaching: {
			title: 'Nyelvi Coaching?',
			parts: [
				{
					subtitle: 'Mi a nyelvi coaching?',
					texts: [
						'Olyan idegennyelvi fejlesztési módszer és eszköztár, amely ötvözi a nyelvtanulást, az idegennyelvi készségek és képességek fejlesztését és az önfejlesztés coaching módszereit.',
					],
				},
				{
					subtitle: 'Kinek lehet hasznos a nyelvi coaching?',
					texts: [
						'Minden nyelvtanulónak, aki motivált, de nincs önbizalma az idegennyelv használat terén',
						'Az üzleti életben részt vevőknek, akiknek bár szükségük van a rendszeres idegennyelv használatra, az adott helyzetekben bátortalannak, kevésbé magabiztosnak érzik magukat',
						'Mindenkinek, akik célzott feladatra fókuszálva szeretnék fejleszteni angol nyelvi készégeiket, képességeiket: prezentációk, interjúk, meetingek, stb',
					],
				},
				{
					subtitle: 'Miben más a nyelvi coaching?',
					texts: [
						'A coach és a coachee/nyelvtanuló között mindig egy bizalmi alapon nyugvó, egyenrangú kapcsolat van. Együtt tűzik ki a célt, melynek elérésében a coach folyamatosan támogatja, segíti a coacheet. A folyamatban mindvégig fókuszban van a berögzült frusztrációk, belső akadályok, gátak, akadályozó hiedelmek feloldása, az önbizalom és a nyelvtanulással kapcsolatos sikerélmény növelése.',
					],
				},
			],
		},
		footer: {
			coTitle: 'Céges adatok',
			hq: 'Székhely: 2120 Dunakeszi Kolonics György utca 2.',
			taxNr: 'Adószám: 55414317-1-33',
			regNr: 'Nyilvántartási szám: 54089761',
			contactTitle: 'Kapcsolat',
			email: 'nagybori@connectcoaching.hu',
			phone: '+36 30 997 7201',
		},
		pricing: {
			title: 'Árak',
			online: 'Minden szolgáltatás igénybevehető online is.',
			discounts: 'Diákoknak és pedagógusoknak 15% kedvezmény',
			packages: [
				{
					title: 'Bevezető beszélgetés',
					text: 'Messengeren vagy Skypon',
					price: 'Ingyenes - 20perc',
					btnTxt: 'Jelentkezem',
				},
				{
					title: 'Bevezető Alkalom',
					lastPrice: '12 000 Ft/60 perc',
					btnTxt: 'Jelentkezem',
				},
				{
					title: 'Connect Start',
					text: '3 Alkalom',
					lastPrice: '45 000 Ft',
					additional: '15 000 Ft/60 perc',
					btnTxt: 'Jelentkezem',
				},
				{
					title: 'Connect Complex',
					text: '5 Alkalom',
					lastPrice: '65 000 Ft',
					additional: '13 000 Ft/60 perc',
					btnTxt: 'Jelentkezem',
				},
				{
					title: 'Connect Change',
					text: '8 Alkalom',
					lastPrice: '96 000 Ft',
					additional: '12 000 Ft/60 perc',
					btnTxt: 'Jelentkezem',
				},
				{
					title: 'Connect Egyéni Csomag',
					text: 'Ha más elképzelésed van, kérlek, vedd fel velem a kapcsolatot!',
					btnTxt: 'Kapcsolat',
				},
			],
		},
	},
};
