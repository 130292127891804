import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import aboutConnect from 'src/images/about-connect.png';

import { Section, Title, Text, TextContainer, Container, SubTitle } from 'src/theme/styledComponents';

const Wrapper = styled(Container)`
	min-height: 100vh;
	color: ${props => props.theme.colors.secondary};
`;

const Img = styled.img`
	margin: 50px 0px;
	width: 60%;
	transition: width 0.3s;
	@media (max-width: 1200px) {
		width: 80%;
	}
`;

const AboutConnect = () => {
	const { t: translate } = useTranslation();

	return (
		<Section background="lighterPurpleShadow" id="about-me">
			<Wrapper>
				<Title style={{ marginBottom: '30px' }} color="secondary">
					{translate('connect.title')}
				</Title>
				<Img src={aboutConnect} alt="About connect" />
				<TextContainer>
					<Text style={{ marginTop: '20px' }} fontSize="26px;">
						{translate('connect.subtitle')}
					</Text>
					<SubTitle style={{ width: '100%' }}>{translate('connect.subtitle2')}</SubTitle>
					<ul>
						{translate('connect.texts', { returnObjects: true }).map((text, index) => (
							<li key={index}>
								<Text key={index}>{text}</Text>
							</li>
						))}
					</ul>
				</TextContainer>
			</Wrapper>
		</Section>
	);
};

export default AboutConnect;
