import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import aboutMeImg from 'src/images/about-me-min.jpg';
import { Section, Title, Text, TextContainer, Container } from 'src/theme/styledComponents';

const Wrapper = styled(Container)`
	min-height: 100vh;
	color: ${props => props.theme.colors.light};
`;

const Texts = styled(TextContainer)`
	margin: 42px 0;
	${Text}:nth-child(2) {
		opacity: 0;
		max-height: 0;
		pointer-events: none;
	}
	${props =>
		props.more &&
		css`
			${Text}:nth-child(2) {
				opacity: 1;
				max-height: unset;
				pointer-events: all;
				transition: opacity 1s ease;
			}
		`}
`;

const Img = styled.img`
	width: 50vh;
	border-radius: 10px;
`;

const AboutMe = () => {
	const { t: translate } = useTranslation();

	return (
		<Section background="primary" id="about-me">
			<Wrapper>
				<Title color="light">{translate('aboutMe.title')}</Title>
				<Img src={aboutMeImg} alt="About Me" />
				<Texts more={true}>
					<Text>{translate('aboutMe.p1')}</Text>
					<Text>{translate('aboutMe.p2')}</Text>
				</Texts>
			</Wrapper>
		</Section>
	);
};

export default AboutMe;
