import styled from 'styled-components';

export const Section = styled.section`
	padding: 60px 0px;
	min-width: 250px;
	background: ${props => props.background && props.theme.colors[props.background]};
	& > * {
		width: 75%;
		margin: 0 auto;
	}
	@media (max-width: 1300px) {
		& > * {
			width: 85%;
		}
	}
	@media (max-width: 768px) {
		& > * {
			width: 100%;
		}
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
`;

export const Button = styled.button`
	border: 0;
	cursor: ${props => props.cursor || 'pointer'};
	border-radius: 6px;
	margin: ${props => props.margin || '0px'};
	padding: ${props => props.padding || '10px 20px'};
	font-size: 20px;
	font-weight: bold;
	color: ${props => props.theme.colors.light};
	background: ${props => (props.background ? props.theme.colors[props.background] : props.theme.colors.primary)};
`;

export const Link = styled.a`
	border: 0;
	display: block;
	cursor: pointer;
	border-radius: 6px;
	margin: ${props => props.margin || '0px'};
	padding: ${props => props.padding || '10px 20px'};
	font-size: 20px;
	font-weight: bold;
	color: ${props => props.theme.colors.light};
	background: ${props => (props.background ? props.theme.colors[props.background] : props.theme.colors.primary)};
	display: block;
	text-decoration: none;
	text-align: center;
	margin: 15px 10px;
	transition: color 0.5s;
`;

export const Title = styled.div`
	font-size: 54px;
	font-weight: bold;
	text-align: ${props => props.center && 'center'};
	color: ${props => props.color && props.theme.colors[props.color]};
	margin-bottom: ${props => props.marginBottom || '30px'};
	@media (max-width: 768px) {
		font-size: 40px;
	}
	@media (max-width: 500px) {
		font-size: 30px;
	}
`;

export const TextContainer = styled.div`
	width: 60%;
	display: grid;
	grid-gap: 30px;
	margin: 0 auto;
	text-align: justify;
	@media (max-width: 600px) {
		width: 90%;
	}
`;

export const Text = styled.div`
	font-size: ${props => props.fontSize || '20px'};
	font-weight: ${props => props.bold && 'bold'};
	line-height: ${props => props.lineHeight || '30px'};
	text-align: ${props => props.textAlign || 'justify'};
	width: 100%;
	@media (max-width: 768px) {
		font-size: 20px;
	}
	@media (max-width: 500px) {
		font-size: 16px;
	}
`;

export const SubTitle = styled(Text)`
	text-align: ${props => props.textAlign || 'center'};
	font-size: 36px;
	font-weight: ${props => (props.bold ? 'bold' : 'normal')};
	padding: ${props => props.padding || '25px'};
	@media (max-width: 768px) {
		font-size: 25px;
	}
`;
