import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import Support from 'src/images/services/support.png';
import Strength from 'src/images/services/strength.png';
import Plan from 'src/images/services/plan.png';
import Sketch from 'src/images/services/sketch.png';
import Question from 'src/images/services/question.png';
import Solution from 'src/images/services/solution.png';
import Interest from 'src/images/services/interest.png';

const Card = styled.div`
	width: 400px;
	height: 100px;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 20px;
	margin-bottom: 30px;
	${props => css`
		color: ${props.theme.colors.light};
		background: ${props.theme.colors.light};
		box-shadow: 4px 4px 16px ${props.theme.colors.darkerPurpleShadow};
	`}
`;

const VerticalLine = styled.div`
	height: 100%;
	width: 3px;
	border-radius: 1px;

	background-color: ${props => props.theme.colors.greyShadow};
	margin: 10px;
`;

const CardText = styled.span`
	flex-basis: auto;
	font-weight: bold;
	color: ${props => props.color};
`;

const Img = ({ imgSource, alt }) => <img src={imgSource} alt={alt} style={{ width: '70px', height: '70px', marginRight: '5px' }} />;

const ServiceCard = ({ png, alt, text, color }) => (
	<Card>
		<Img imgSource={png} alt={alt} />
		<VerticalLine />
		<CardText color={color}>{text}</CardText>
	</Card>
);

const CardList = () => {
	const { t: translate } = useTranslation();
	const { support, solution, interest, strength, question, plan, sketch } = translate('services.cards', { returnObjects: true });
	const cardList = [
		{
			png: Support,
			alt: 'support',
			...support,
		},
		{
			png: Solution,
			alt: 'solution',
			...solution,
		},
		{
			png: Interest,
			alt: 'interest',
			...interest,
		},
		{
			png: Strength,
			alt: 'strength',
			...strength,
		},
		{
			png: Question,
			alt: 'question',
			...question,
		},
		{
			png: Plan,
			alt: 'plan',
			...plan,
		},
		{
			png: Sketch,
			alt: 'sketch',
			...sketch,
		},
	];

	return cardList.map((card, index) => <ServiceCard key={index} {...card} />);
};

export default CardList;
