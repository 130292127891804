import React from 'react';
import PropTypes from 'prop-types';
import { TextContainer, Text } from 'src/theme/styledComponents';
import aboutCoachingImg from 'src/images/about-coaching.png';
import styled from 'styled-components';

const Img = styled.img`
	padding: 40px;
	width: 50vh;
	:last-of-type {
		display: none;
	}
	transition: width 0.3s;
	@media (max-width: 500px) {
		width: 80%;
	}
`;

const Important = styled.span`
	text-transform: uppercase;
	font-weight: bold;
	color: ${props => props.theme.colors.yellow};
`;

const CoachingDetail = ({ title, texts, important }) => (
	<>
		<Img src={aboutCoachingImg} alt="About Coaching" />
		<TextContainer style={{ marginTop: '30px' }}>
			<Text fontSize="36px" lineHeight="40px" textAlign="left" className="sub-title">
				{title}
			</Text>
			<ul>
				{texts.map((text, index) => (
					<li key={index}>
						<Text key={index}>
							{important && <Important>{important}</Important>}
							{text}
						</Text>
					</li>
				))}
			</ul>
		</TextContainer>
	</>
);

CoachingDetail.propTypes = {
	title: PropTypes.string,
	texts: PropTypes.arrayOf(PropTypes.string),
	important: PropTypes.string,
};

export default CoachingDetail;
