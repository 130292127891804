import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section, Title, Container, SubTitle } from 'src/theme/styledComponents';
import styled from 'styled-components';
import CardList from './components/CardList';

const Wrapper = styled(Container)`
	min-height: 60vh;
	justify-content: flex-start;
	color: ${props => props.theme.colors.light};
`;

const ColoredSubTitle = styled(SubTitle)`
	color: ${props => props.theme.colors.secondary};
	text-align: center;
	margin-bottom: 20px;
`;

const CardListWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
	@media (min-width: 1650px) {
		max-width: 70%;
	}
`;

const Services = () => {
	const { t: translate } = useTranslation();
	return (
		<Section id="services" background="lighterPurpleShadow">
			<Wrapper>
				<Title style={{ marginBottom: '20px' }} color="secondary">
					{translate('services.title')}
				</Title>
				<ColoredSubTitle>{translate('services.toOffer')}</ColoredSubTitle>
				<CardListWrapper>
					<CardList />
				</CardListWrapper>
			</Wrapper>
		</Section>
	);
};

export default Services;
