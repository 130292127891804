import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InstaIcon from 'src/images/insta.png';
import FacebookIcon from 'src/images/facebook.png';
import LinkedinIcon from 'src/images/linkedin.png';
import { Section, Container, SubTitle, Text } from 'src/theme/styledComponents';

const Wrapper = styled(Container)`
	min-height: 30vh;
	color: ${props => props.theme.colors.light};
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-start;
	@media (max-width: 1200px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 30px;
	}
`;

const Column = styled.div`
	text-align: left;
`;

const SocialLink = styled.a`
	display: block;
	background-size: cover;
	background-position: center center;
	background-image: url(${props => props.icon});
	background-repeat: no-repeat;
	object-fit: contain;
	width: 32px;
	height: 32px;
`;

const IconWrapper = styled.div`
	display: flex;
	margin-top: 30px;
	justify-content: space-around;
`;

const Footer = () => {
	const { t: translate } = useTranslation();
	return (
		<Section background="primary">
			<Wrapper>
				<Column>
					<SubTitle bold padding="20px 0px" textAlign="left">
						{translate('footer.coTitle')}
					</SubTitle>
					<Text>{translate('footer.hq')}</Text>
					<Text>{translate('footer.taxNr')}</Text>
					<Text>{translate('footer.regNr')}</Text>
				</Column>
				<Column>
					<SubTitle bold padding="20px 0px" textAlign="left">
						{translate('footer.contactTitle')}
					</SubTitle>
					<Text>
						<a style={{ color: 'white' }} href={`mailto:${translate('footer.email')}`}>
							{translate('footer.email')}
						</a>
					</Text>
					<Text>
						<a style={{ color: 'white' }} href={`tel:${translate('footer.phone')}`}>
							{translate('footer.phone')}
						</a>
					</Text>
					<IconWrapper>
						<SocialLink icon={FacebookIcon} target="_blank" href="https://www.facebook.com/nagyboricoaching" />
						<SocialLink icon={InstaIcon} target="_blank" href="https://www.instagram.com/connectcoachinsta" />
						<SocialLink icon={LinkedinIcon} target="_blank" href="https://linkedin.com/in/borbála-nagy-19700553" />
					</IconWrapper>
				</Column>
			</Wrapper>
		</Section>
	);
};

export default Footer;
