import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Title, Text, Link } from 'src/theme/styledComponents';

const Card = styled.div`
	display: grid;
	grid-gap: 5%;
	grid-template-columns: 45% 50%;
	${Link} {
		margin-top: 20px;
	}
	:last-of-type {
		padding: 35px 30px;
		border-radius: 6px;
		grid-template-columns: 100%;
		${props => css`
			color: ${props.theme.colors.light};
			background: ${props.theme.colors.secondary};
			box-shadow: 4px 4px 16px ${props.theme.colors.darkerPurpleShadow};
			${Link} {
				color: ${props.theme.colors.secondary};
				background: ${props.theme.colors.light};
			}
		`}
		${Text} {
			width: 70%;
			align-self: flex-end;
		}
	}
	@media (max-width: 1024px) {
		grid-template-columns: 100%;
		${Link} {
			width: 140px !important;
			font-size: 15px;
			padding: 10px 25px;
			margin: 15px 0 0 auto;
		}
		:last-of-type ${Text} {
			width: 100%;
		}
	}
`;

const CardTitle = styled(Title)`
	font-size: 32px;
	line-height: 46px;
	color: ${props => props.theme.colors.light};
	*${Card}:not(:last-of-type) & {
		border-radius: 6px;
		padding: 35px 80px 35px 40px;
		box-shadow: 4px 4px 16px ${props => props.theme.colors.greyShadow};
	}
	*${Card}:nth-child(1) & {
		color: ${props => props.theme.colors.secondary};
	}
	*${Card}:nth-child(2) & {
		${props => css`
			background: ${props.theme.colors.brown};
			box-shadow: 4px 4px 16px ${props.theme.colors.lighterBrown};
		`}
	}
	*${Card}:nth-child(3) & {
		${props => css`
			background: ${props.theme.colors.yellow};
			box-shadow: 4px 4px 16px ${props.theme.colors.lighterYellow};
		`}
	}
	*${Card}:nth-child(4) & {
		${props => css`
			background: ${props.theme.colors.purple};
			box-shadow: 4px 4px 16px ${props.theme.colors.ligtherPurple};
		`}
	}
	*${Card}:nth-child(5) & {
		${props => css`
			background: ${props.theme.colors.secondary};
			box-shadow: 4px 4px 16px ${props.theme.colors.darkerPurpleShadow};
		`}
	}
	@media (max-width: 900px) {
		font-size: 26px;
		*${Card}:not(:last-of-type) & {
			padding: 20px;
		}
	}
`;

const PriceCard = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	*${Card}:not(:last-of-type) & {
		padding: 25px 30px;
		border-radius: 6px;
		box-shadow: 4px 4px 16px ${props => props.theme.colors.lighterPurpleShadow};
		${Text}:first-child {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	${Link} {
		width: 220px;
		align-self: flex-end;
	}
	@media (max-width: 500px) {
		flex-direction: column;
		${Link}, ${Text} {
			width: auto !important;
			margin: 10px 0 !important;
			align-self: center;
		}
		${Text}:first-child {
			font-size: 22px;
		}
		*${Card}:not(:last-of-type) & {
			${Text} {
				text-align: center;
			}
		}
	}
`;

const Price = styled(Text)`
	font-size: 22px;
	align-self: flex-end;
	@media (max-width: 900px) {
		font-size: 20px;
	}
`;

// text-decoration: line-through;
const LastPrice = styled(Text)`
	font-size: 22px;
	align-self: flex-end;
	@media (max-width: 900px) {
		font-size: 20px;
	}
`;

const Additional = styled(Text)`
	color: #c3cbd9;
	font-style: italic;
	font-size: 15px;
	${props => css`
		color: ${props.index === 2
			? props.theme.colors.yellow
			: props.index === 3
			? props.theme.colors.purple
			: props.index === 4
			? props.theme.colors.secondary
			: null};
	`}
`;

const Occasion = styled.div`
	font-size: 20px;
	text-decoration: none;
	font-weight: normal;
	@media (max-width: 900px) {
		font-size: 10px;
	}
`;

const LastPriceWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const PricingCard = ({ title, text, additional, lastPrice, occasion, btnTxt, index }) => {
	return (
		<Card>
			<CardTitle marginBottom="0">{title}</CardTitle>
			<PriceCard>
				<Text>{text}</Text>
				<LastPriceWrapper>
					{lastPrice && [<LastPrice>{lastPrice}</LastPrice>, occasion && <Occasion> /{occasion}</Occasion>]}
				</LastPriceWrapper>
				{additional && <Additional index={index}>{additional}</Additional>}
				<Link href="#contact-form" background="secondary">
					{btnTxt}
				</Link>
			</PriceCard>
		</Card>
	);
};

PricingCard.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	price: PropTypes.string,
	occasion: PropTypes.string,
	btnTxt: PropTypes.string,
};

export default PricingCard;
