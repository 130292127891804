import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Title, Section, Container, Text, TextContainer } from 'src/theme/styledComponents';

const Wrapper = styled(Container)`
	color: ${props => props.theme.colors.light};
`;

const Texts = styled(TextContainer)`
	grid-gap: 15px;
`;

const ListItem = styled(Text)`
	display: flex;
`;

const LanguageCoaching = () => {
	const { t: translate } = useTranslation();
	return (
		<Section id="language-coaching" background="primary">
			<Wrapper>
				<Title style={{ marginBottom: '30px' }} center>
					{translate('languageCoaching.title')}
				</Title>
				<Texts>
					{translate('languageCoaching.parts', { returnObjects: true }).map(textObject => (
						<div style={{ marginTop: '20px' }}>
							<Text fontSize="36px" lineHeight="40px" textAlign="left" style={{ marginBottom: '20px' }}>
								{textObject.subtitle}
							</Text>
							<ul>
								{textObject.texts.map((text, index) => (
									<li>
										<ListItem key={index}>
											<Text>{text}</Text>
										</ListItem>
									</li>
								))}
							</ul>
						</div>
					))}
				</Texts>
			</Wrapper>
		</Section>
	);
};

export default LanguageCoaching;
